//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  data() {
    var validatePass = (rule, value, callback) => {
      if (!value) {
        callback(new Error("请输入确认密码"));
      } else if (value !== this.ruleForm.newPwd) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };
    return {
      loading: false,
      ruleForm: {
        newPwd: null,
        secPwd: null,
      },
      rules: {
        newPwd: [
          {
            required: true,
            message: "请输入新密码",
            trigger: "blur",
          },
        ],
        secPwd: [
          {
            validator: validatePass,
            required: true,
            trigger: "blur",
          },
        ],
      },
    };
  },
  mounted() {},
  methods: {
    reset() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.loading = true;
          const data = {
            userID: this.$route.query.userID,
            password: this.ruleForm.newPwd,
          };
          this.$http
            .post("/ResetPassword.ashx", data)
            .then((res) => {
              if (res.res == 0) {
                this.$message.success("修改密码成功返回登录页面");
                this.$router.push("/");
              } else {
                this.$message.error(res.msg);
              }
            })
            .finally(() => (this.loading = false));
        } else {
          return false;
        }
      });
    },
  },
  computed: {
    isSell() {
      return process.env.VUE_APP_SELL;
    },
  },
};
